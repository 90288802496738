<template>
  <div>
    <template v-if="isStrandApproach">
      <div class="list-title flex py-3 text-center">
        <h5 :class="isMYPSection ? 'w-1/3' : 'w-1/2'"></h5>
        <h5 class="w-1/5" v-if="isMYPSection">
          <span class="block mb-2">Achievements levels </span>
          <span class="flex justify-between">
            <small v-for="(c, i) in criterias" :key="i">{{
              c.key | text_formatter
            }}</small>
          </span>
        </h5>
        <h5 class="w-1/4">Criteria</h5>
        <h5 class="w-1/4 text-capitalize">{{ $t('grading.notes') }}</h5>
      </div>
      <ul class="comment-details">
        <li
          v-for="(d, i) in comments"
          :key="i"
          class="w-full mb-5 p-3 border-b"
          :class="{'bg-gray': !d.allocation.add_to_grading_allocation}">
          <h4 class="text-primary-light w-1/2 mb-4">
            <strong> {{ d.subject_name }}</strong>
          </h4>
          <div
            v-for="strand in d.strands"
            :key="`strand_${strand.id}`"
            class="flex w-full my-2">
            <h5 class="w-1/2">
              {{ strand.strand.description }}
            </h5>
            <p class="w-1/4 text-center">
              {{ strand.assement }}
            </p>
            <p class="w-1/4 text-center">
              {{ strand.other_assement }}
            </p>
          </div>

          <div class="w-full" v-if="d.teacher_comment">
            <h5 class="bold my-3">
              {{ d.teacher_comment.user.name || '' }}
            </h5>
            <p>
              {{ d.teacher_comment.note || '' }}
            </p>
          </div>
        </li>
      </ul>
    </template>
    <template v-else>
      <div class="list-title flex py-3 text-center">
        <h5 :class="hasLocalGrade || showGP ? 'w-1/3' : 'w-2/3'"></h5>
        <h5 class="w-1/5" v-if="isMYPSection">
          <span class="block mb-2">Achievements levels </span>
          <span class="flex justify-between">
            <small v-for="(c, i) in criterias" :key="i">{{
              c.key | text_formatter
            }}</small>
          </span>
        </h5>
        <h5 class="w-1/6" v-if="!isAmerican">Final Grade</h5>
        <h5 class="w-1/6" v-if="showGP">GP</h5>
        <h5 class="w-1/6">Boundaries</h5>
        <h5 class="w-1/6" v-if="showPrecentange">Percentage</h5>
        <h5 class="w-1/6" v-if="hasLocalGrade">School Grade</h5>
        <h5 class="w-1/6" v-if="isAmerican">Credits</h5>
      </div>
      <ul class="comment-details">
        <li
          v-for="(d, i) in comments"
          :key="i"
          class="w-full mb-5 p-3"
          :class="{'bg-gray': !d.allocation.add_to_grading_allocation}">
          <div class="flex w-full">
            <p
              class="text-primary-light"
              :class="hasLocalGrade || showGP ? 'w-1/3' : 'w-2/3'">
              {{ d.subject_name }}
            </p>
            <p
              class="w-1/5 text-center flex justify-between"
              v-if="isMYPSection">
              <span v-for="(c, i) in criterias" :key="i">{{
                d.criteria[c.key]
              }}</span>
            </p>
            <template>
              <p class="w-1/6 text-center" v-if="!isAmerican">
                {{ d.boundary_limits ? d.boundary_limits.grade : '-' }}
              </p>
              <p class="w-1/6 text-center" v-if="showGP">
                {{ d.gp || '-' }}
              </p>
              <p class="w-1/6 text-center">
                {{
                  d.boundary_limits
                    ? Math.round(d.boundary_limits.min) +
                      ' - ' +
                      Math.round(d.boundary_limits.max) +
                      ' %'
                    : '-'
                }}
              </p>
              <p class="w-1/6 text-center" v-if="showPrecentange">
                {{ d.total_score_percentage || '-' }} %
              </p>

              <p class="w-1/6 text-center" v-if="hasLocalGrade">
                {{ d.boundary_limits ? d.boundary_limits.local_grade : '-' }}
              </p>
              <p class="w-1/6 text-center" v-if="isAmerican">
                {{ d.allocation.weight || '-' }}
              </p>
            </template>
          </div>
          <div class="w-full" v-if="d.teacher_comment">
            <h5 class="bold my-3">
              {{ d.teacher_comment.user.name || '' }}
            </h5>
            <p>
              {{ d.teacher_comment.note || '' }}
            </p>
          </div>
        </li>
      </ul>
    </template>
  </div>
</template>
<script>
import gradingMixins from '@/modules/grading/grading.mixins.js'

export default {
  name: 'comment',
  props: ['comments'],
  mixins: [gradingMixins],
  computed: {
    hasLocalGrade() {
      const firstEl = this.comments[0]
      return (
        (firstEl.boundary_id &&
          firstEl.boundary_limits?.local_grade !== null) ||
        false
      )
    },
    isStrandApproach() {
      return Boolean(this.reportCardData?.student_data?.strands?.length)
    },
    includedInAllocation() {
      return this.comments.some((a) => a.allocation.add_to_grading_allocation)
    },
    showGP() {
      return (
        this.isAmerican && this.includedInAllocation && this.showSubjectWeight
      )
    },
    showPrecentange() {
      return this.showSubjectPercentage && this.isAmerican
    }
  }
}
</script>
<style lang=""></style>
