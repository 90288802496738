<template>
  <article
    class="reportCardPage"
    :class="pageClasses"
    :style="`background-image:url(${bg})`">
    <div class="reportCardPage-header">
      <p class="w-1/3">{{ studentName }}</p>
      <p class="w-1/3 text-center mx-auto">
        {{
          `${divisionName || ''} - ${sectionName || ''} - ${className || ''}`
        }}
      </p>
      <p class="w-1/3 text-right">
        <span v-if="academicYear">{{ academicYear }}</span>
        <span v-if="termName" class="ml-5">{{ termName }}</span>
      </p>
    </div>
    <div
      class="p-4 reportCardPage-body"
      :style="bodyBg ? `background-image:url(${bodyBg})` : ''"
      :class="bodyClasses">
      <slot></slot>
    </div>
    <div class="reportCardPage-footer">
      <p class="w-1/3">
        <!-- <img
          :src="require('@/assets/images/logo/favicon.png')"
          width="25"
          class="mr-3 inline-block"
          style="object-fit: contain"
        /> -->
        {{ $t('basic.powered_by') | text_formatter }}
        <a :href="appLink" target="_blank" rel="nofollow" class="text-italic">{{
          'schoolia-eg.com'
        }}</a>
      </p>
      <h4 class="w-1/3 text-center currentPage bold">{{ currentPage }}</h4>
      <p class="bold w-1/3 text-right">
        {{ $t('basic.page') | text_formatter }}
        <span class="currentPage"> {{ currentPage }}</span>
        {{ $t('basic.of') }}
        {{ reportCardPagesLength }}
      </p>
    </div>
  </article>
</template>
<script>
import gradingMixins from '@/modules/grading/grading.mixins.js'
export default {
  name: 'page-layout',
  mixins: [gradingMixins],
  props: {
    bg: {type: String, require: false, default: ''},
    bodyBg: {type: String, require: false, default: ''},
    bodyClasses: {type: String, require: false, default: ''},
    pageClasses: {type: String, require: false, default: ''}
  },
  computed: {
    studentName() {
      return (
        this.selectedStudent?.student_full_name || this.$route.query.studentName
      )
    },
    gradeName() {
      return this.selectedStudent?.grade?.name || ''
    },
    className() {
      return this.selectedStudent?.class?.name || ''
    },
    currentPage() {
      return 0
    }
  },
  created() {
    // console.log(this.$parent.$el)
  }
}
</script>
<style lang="scss">
@import 'style';
</style>
