<template>
  <div>
    <template v-if="activeRound.id">
      <!-- Configuration -->
      <vx-card
        class="p-4 mb-10"
        :title="$t('basic.configurations')"
        v-if="checkUserRole('SND')">
        <div class="mb-5">
          <label>Cover title</label>
          <vs-input
            class="mb-3"
            v-model="coverStatic.line1"
            placeholder="line 1" />
        </div>
        <!-- Intro -->
        <div class="mb-5 flex items-center">
          <label class="mr-3">
            {{ $t('grading.include_the_report_card_intro') }}:
          </label>
          <vs-switch v-model="showTheIntro" />
        </div>
        <div class="mb-5" v-if="showTheIntro">
          <label>Schoolia Principle Intro </label>
          <quill-editor
            v-model="staticContent.schoolPrincipleIntro.introText"
            class="w-full mb-3"
            name=""></quill-editor>
        </div>
        <div class="mb-5 flex items-center">
          <label class="mr-3"> Primary Color: </label>
          <input type="color" v-model="reportCardPrimaryColor" class="mx-3" />
        </div>

        <div class="mb-5 flex items-center">
          <label class="mr-3">
            {{ $t('grading.include_the_IB_Learner_profile') }}:
          </label>
          <vs-switch v-model="showIBProfile" :disabled="!isIB" />
        </div>
        <div class="mb-5 flex items-center">
          <label class="mr-3">
            {{ $t('grading.include_the_approach_to_learning') }}:
          </label>
          <vs-switch v-model="showApproachToLearning" :disabled="!includeATL" />
        </div>
        <div class="mb-5 flex items-center" v-if="allowCoordinatorComment">
          <label class="mr-3">
            {{ $t('grading.include_the_advisors_comment') }}:
          </label>
          <vs-switch v-model="showAdvisorsComment" />
        </div>
        <div class="mb-5 flex items-center">
          <label class="mr-3">
            {{ $t('grading.include_subject_Percentage') }}:
          </label>
          <vs-switch v-model="showSubjectPercentage" />
        </div>
        <div class="mb-5 flex items-center">
          <label class="mr-3">
            {{ $t('grading.include_subject_Weight') }}:
          </label>
          <vs-switch v-model="showSubjectWeight" />
        </div>
      </vx-card>
      <!-- Preview -->
      <h4 class="mb-3">{{ $t('basic.preview') }}</h4>

      <div class="mb-3">
        <!-- Cover -->
        <section
          class="reportCardPage reportCardPage_cover"
          :style="`background-image:url('${coverStatic.bg}')`">
          <div class="reportCardPage-body my-auto">
            <img class="logo mb-4 w-1/2 mx-auto h-auto" :src="logoImg" />
            <h1 class="text-center text-primary mb-1">
              {{ coverStatic.line1 }}
            </h1>
            <h1
              class="text-center text-primary mb-1"
              v-if="roundDate || roundName">
              {{
                roundName + (roundDate && roundName ? ' - ' : '') + roundDate
              }}
            </h1>
            <h1
              class="text-center text-primary"
              v-if="divisionName || sectionName">
              {{
                divisionName +
                (divisionName && sectionName ? ' - ' : '') +
                sectionName
              }}
            </h1>

            <div class="student-details">
              <h3>
                <strong class="block">{{ studentName }}</strong>
                <span class="block">{{ className }}</span>
                <span>{{ academicYear }}</span>
              </h3>
            </div>
          </div>
        </section>
        <!-- school principle intro -->
        <section class="" v-if="showTheIntro">
          <page :bg="schoolPrincipleIntroStatic.bg" bodyClasses="my-auto">
            <div class="min-height-vh flex justify-center flex-col">
              <div
                class="mb-10"
                v-html="schoolPrincipleIntroStatic.introText"></div>
              <div class="flex justify-end">
                <h4
                  v-for="(s, i) in schoolPrincipleIntroStatic.signatures"
                  :key="i"
                  class="text-center mx-3">
                  <strong class="block mb-3">{{ s.name }}</strong>
                  <span>{{ s.position }}</span>
                </h4>
              </div>
            </div>
          </page>
        </section>
        <!-- learnerProfile -->
        <section class="" v-if="isIB && showIBProfile">
          <page :bg="learnerProfileStatic.bg" pageClasses="bg-img-contain">
            <h2 class="section-title">{{ divisionName }} Learner profile</h2>
            <img
              :src="learnerProfileStatic[sectionName].img"
              class="mx-auto w-1/4 mt-3 mb-20" />
            <ul class="guide-details">
              <li
                v-for="(g, i) in learnerProfileStatic[sectionName].guide"
                :key="i">
                <h3 class="w-1/5 title">{{ g.title }}</h3>
                <p class="w-4/5">{{ g.desc }}</p>
              </li>
            </ul>
          </page>
        </section>
        <!-- descriptors  -->
        <section class="" v-if="showBoundariesAndDescriptors">
          <page :bg="descriptorsStatic.bg" pageClasses="bg-img-contain">
            <h2 class="section-title">Grades descriptors</h2>
            <!-- Loader -->
            <div
              id="loadingDescriptors"
              v-if="loadingDescriptors"
              ref="loadingDescriptors"
              class="vs-con-loading__container"></div>
            <div class="list-title flex py-3 text-center">
              <h5 class="w-1/5">Final Grade</h5>
              <h5 class="w-1/5" v-if="hasBoundaryLocalGrade">Local Grade</h5>
              <h5 class="w-1/5">Boundaries</h5>
              <h5 class="w-2/3" v-if="hasBoundaryDescriptor">Descriptors</h5>
            </div>
            <ul class="guide-details">
              <li v-for="(d, i) in descriptors" :key="i">
                <h5 class="bold w-1/5 text-center">
                  {{ d.grade }}
                </h5>
                <h5 class="bold w-1/5 text-center" v-if="hasBoundaryLocalGrade">
                  {{ d.local_grade }}
                </h5>
                <h5 class="bold w-1/5 text-center">
                  {{ Math.round(d.min) }} - {{ Math.round(d.max) }} %
                </h5>
                <p class="w-2/3" v-if="hasBoundaryDescriptor">
                  {{ d.dscriptor }}
                </p>
              </li>
            </ul>
          </page>
        </section>
        <!-- POI  -->
        <section class="" v-if="showPOIUnits">
          <page pageClasses="bg-img-contain">
            <h2 class="section-title">POI Units</h2>
            <POIUnits />
          </page>
        </section>

        <!-- Summery of achievements Core subjects  -->
        <section class="">
          <page
            :bg="achievementsStatic.bg"
            pageClasses="bg-img-contain"
            v-for="(time, i) in new Array(coreSubjectRepeatTimes)"
            :key="i">
            <h2 class="section-title">Summery of achievements Core subjects</h2>

            <comment :comments="sliceArr(achievementCoreSubjects, i + 1)" />
            <h4
              v-if="showFinalGPA && !achievementMOESubjects.length"
              class="text-center bold my-10">
              {{ isDPSection ? 'total' : ' final GPA' }} : {{ finalGPA }}
            </h4>
          </page>
        </section>
        <!-- Summery of achievements MOE Subjects  -->
        <section class="" v-if="achievementMOESubjects.length">
          <page
            :bg="achievementsStatic.bg"
            v-for="(time, i) in new Array(MOESubjectsRepeatTimes)"
            :key="i">
            <h2 class="section-title">Summery of achievements MOE subjects</h2>
            <comment :comments="sliceArr(achievementMOESubjects, i + 1)" />
            <h4
              v-if="showFinalGPA && achievementMOESubjects.length"
              class="text-center bold my-10">
              {{ isDPSection ? 'total' : ' final GPA' }} : {{ finalGPA }}
            </h4>
          </page>
        </section>
        <!-- approachToLearning-->
        <section class="" v-if="showApproachToLearning && includeATL">
          <page :bg="approachToLearningStatic.bg" pageClasses="bg-img-contain">
            <h2 class="section-title">Approaches to learning</h2>
            <div class="flex justify-center flex-col min-height-vh">
              <!-- Subject Table -->
              <div class="table mb-20 w-full">
                <div class="table_title flex py-3 text-center">
                  <p class=""></p>
                  <h6 class="flex-1 text-center">Subject</h6>
                  <h6
                    class="flex-1 text-primary-light bold"
                    v-for="(v, i) in ATLCategories"
                    :key="'atlC_' + i">
                    {{ v.name }}
                  </h6>
                </div>
                <ul class="table_list">
                  <li
                    v-for="(s, i) in achievements"
                    :key="'ach_' + i"
                    class="flex text-center">
                    <p class="">{{ i + 1 }}</p>
                    <p class="flex-1 ml-0 mr-auto">{{ s.subject_name }}</p>
                    <template v-if="s.reportCaredAtls">
                      <p
                        class="flex-1 text-success"
                        v-for="(v, catIndex) in ATLCategories"
                        :key="'atlC_' + catIndex">
                        {{ getNameOfATLOption(s, v.id) }}
                      </p>
                    </template>
                  </li>
                </ul>
              </div>
              <!-- guide  -->
              <div class="mb-0 mt-auto">
                <div class="list-title flex py-3 text-center">
                  <h4 class="w-1/5 text-center">Grade</h4>
                  <h4 class="w-4/5 text-center">Descriptor</h4>
                </div>
                <ul class="guide-details">
                  <li v-for="(g, i) in ATLOptions" :key="i">
                    <h4 class="w-1/5 text-center bold">{{ g.name }}</h4>
                    <p class="w-4/5">{{ g.descriptor }}</p>
                  </li>
                </ul>
              </div>
            </div>
          </page>
        </section>
        <!-- section coordinator comment -->
        <section class="" v-if="showAdvisorsComment">
          <page :bg="coordinatorCommentStatic.bg">
            <h2 class="section-title">Advisors comments</h2>
            <template v-if="sectionCoordinator">
              <div class="min-height-vh">
                <h4>{{ sectionCoordinator.note }}</h4>
              </div>
              <div class="flex justify-end">
                <h4 class="text-center mx-3">
                  <strong class="block mb-3">{{
                    sectionCoordinator.user.name
                  }}</strong>
                  <!--                  <span class="text-primary-light">{{-->
                  <!--                    sectionCoordinator.user.role-->
                  <!--                  }}</span>-->
                </h4>
              </div>
            </template>
          </page>
        </section>
      </div>
      <vx-card>
        <div class="flex justify-center mx-auto w-auto">
          <!-- <vs-button
            class="mt-2 mx-1"
            @click="printPDF"
            :disabled="loadSubmit"
            ref="submitLoader"
            id="submitLoader"
            >{{ $t("basic.save_to_review") }}</vs-button
          > -->
          <vs-button class="mt-2 mx-1" type="border" @click="goToGradeBook"
            >{{ $t('grading.back_to_gradebook') }}
          </vs-button>
          <vs-button
            @click="printPDF"
            :disabled="loadSubmit"
            ref="submitLoader"
            id="submitLoader"
            class="vs-con-loading__container mt-2 mx-2"
            >{{ $t('grading.click_to_add_it_to_the_inventory') }}</vs-button
          >
        </div>
      </vx-card>
    </template>
    <p v-else class="text-danger text-center">
      <span v-if="!loadDialog">
        {{ $t('grading.no_active_round_is_available_yet') }}</span
      >
    </p>
  </div>
</template>
<script>
import gradingMixins from '@/modules/grading/grading.mixins'

// API
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const ReportCardRepo = RepositoryFactory.get('GradingModule', 'reportCard'),
  GradingRoundRepo = RepositoryFactory.get('GradingModule', 'rounds'),
  ExamRepo = RepositoryFactory.get('ExamModule', 'Exam'),
  BoundaryRepo = RepositoryFactory.get(
    'boundariesAndDescriptors',
    'boundariesAndDescriptors'
  )

// const mainImagesPath = '@/assets/images/reportCard'

// Components
import page from './components/pageLayout/page.vue'
import comment from './components/comment'
import POIUnits from './components/POIUnits'

// Editor
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import {quillEditor} from 'vue-quill-editor'

export default {
  name: 'grading-report-card',
  mixins: [gradingMixins],
  components: {page, quillEditor, comment, POIUnits},
  props: {
    content: {type: Object}
  },

  data() {
    return {
      /////////////////////////
      // Loading
      ////////////////////////
      loadingDescriptors: false,
      ////////////////////////
      // Data
      ////////////////////////
      achievements: [],
      studentData: {},
      subjectsData: [],
      isRoundActive: false,
      descriptors: [],
      achievementCoreSubjects: [], // Achievements  included in allocation
      achievementMOESubjects: [], // Achievements Not included in allocation
      pageAchivementsCapacity: 4,
      ATLOptions: [],
      ATLCategories: [],
      POIUnits: [],
      finalGPA: null,

      ////////////////////////////////////
      // Static Content (Configuration)
      ///////////////////////////////////
      reportCardPrimaryColor: null,
      staticContent: {
        cover: {
          line1: 'Report card  ',
          line2: 'Quarter 3 - April’21 ',
          line3: 'IB - MYP',
          bg: require('@/assets/images/reportCard/cover.jpeg')
        },
        schoolPrincipleIntro: {
          bg: require('@/assets/images/reportCard/school_principle_intro.jpeg'),
          introText: '',
          signatures: [
            {
              name: 'Randa Gamal',
              position: 'MYP Coordinator'
            }
            // {
            //   name: 'Yasmin Hamouda',
            //   position: 'School head'
            // }
          ]
        },
        learnerProfile: {
          bg: require('@/assets/images/reportCard/learner_profile.jpg'),

          MYP: {
            img: require('@/assets/images/reportCard/profile_MYP.jpg'),

            guide: [
              {
                title: 'Inquiries',
                desc: 'We nurture our curiosity, developing skills for inquiry and research. We know how to learn independently and with others. We learn with enthusiasm and sustain our love of learning throughout life'
              },
              {
                title: 'Knowledgeable',
                desc: 'We develop and use conceptual understanding, exploring knowledge across a range of disciplines. We engage with issues and ideas that have local and global significance'
              },
              {
                title: 'Thinkers',
                desc: 'We use critical and creative thinking skills to analyse and take responsible action on complex problems. We exercise initiative in making reasoned, ethical decisions'
              },
              {
                title: 'Communicator',
                desc: 'We express ourselves confidently and creatively in more than one language and in many ways. We collaborate effectively, listening carefully to the perspectives of other individuals and groups.'
              },
              {
                title: 'Principled',
                desc: 'We act with integrity and honesty, with a strong sense of fairness and justice, and with respect for the dignity and rights of people everywhere. We take responsibility for our actions and their consequences.'
              },
              {
                title: 'Open-minded',
                desc: 'We critically appreciate our own cultures and personal histories, as well as the values and traditions of others. We seek and evaluate a range of points of view, and we are willing to grow from the experience'
              },
              {
                title: 'Caring',
                desc: 'We show empathy, compassion and respect. We have a commitment to service, and we act to make a positive difference in the lives of others and in the world around us'
              },
              {
                title: 'Risk-takers',
                desc: 'We approach uncertainty with forethought and determination; we work independently and cooperatively to explore new ideas and innovative strategies. We are resourceful and resilient in the face of challenges and change'
              },
              {
                title: 'Balanced',
                desc: 'We understand the importance of balancing different aspects of our lives — intellectual, physical, (spiritual) and emotional — to achieve well-being for ourselves and others. We recognise our interdependence with other people and with the world in which we live'
              },
              {
                title: 'Reflective',
                desc: 'We thoughtfully consider the world and our own ideas and experience. We work to understand our strengths and weaknesses in order to support our learning and personal development.'
              }
            ]
          },
          PYP: {
            img: require('@/assets/images/reportCard/profile_PYP.jpeg'),

            guide: [
              {
                title: 'Inquiries',
                desc: 'We nurture our curiosity, developing skills for inquiry and research. We know how to learn independently and with others. We learn with enthusiasm and sustain our love of learning throughout life'
              },
              {
                title: 'Knowledgeable',
                desc: 'We develop and use conceptual understanding, exploring knowledge across a range of disciplines. We engage with issues and ideas that have local and global significance'
              },
              {
                title: 'Thinkers',
                desc: 'We use critical and creative thinking skills to analyse and take responsible action on complex problems. We exercise initiative in making reasoned, ethical decisions'
              },
              {
                title: 'Communicator',
                desc: 'We express ourselves confidently and creatively in more than one language and in many ways. We collaborate effectively, listening carefully to the perspectives of other individuals and groups.'
              },
              {
                title: 'Principled',
                desc: 'We act with integrity and honesty, with a strong sense of fairness and justice, and with respect for the dignity and rights of people everywhere. We take responsibility for our actions and their consequences.'
              },
              {
                title: 'Open-minded',
                desc: 'We critically appreciate our own cultures and personal histories, as well as the values and traditions of others. We seek and evaluate a range of points of view, and we are willing to grow from the experience'
              },
              {
                title: 'Caring',
                desc: 'We show empathy, compassion and respect. We have a commitment to service, and we act to make a positive difference in the lives of others and in the world around us'
              },
              {
                title: 'Risk-takers',
                desc: 'We approach uncertainty with forethought and determination; we work independently and cooperatively to explore new ideas and innovative strategies. We are resourceful and resilient in the face of challenges and change'
              },
              {
                title: 'Balanced',
                desc: 'We understand the importance of balancing different aspects of our lives — intellectual, physical, (spiritual) and emotional — to achieve well-being for ourselves and others. We recognise our interdependence with other people and with the world in which we live'
              },
              {
                title: 'Reflective',
                desc: 'We thoughtfully consider the world and our own ideas and experience. We work to understand our strengths and weaknesses in order to support our learning and personal development.'
              }
            ]
          },
          DP: {
            img: require('@/assets/images/reportCard/profile_MYP.jpg'),

            guide: [
              {
                title: 'Inquiries',
                desc: 'We nurture our curiosity, developing skills for inquiry and research. We know how to learn independently and with others. We learn with enthusiasm and sustain our love of learning throughout life'
              },
              {
                title: 'Knowledgeable',
                desc: 'We develop and use conceptual understanding, exploring knowledge across a range of disciplines. We engage with issues and ideas that have local and global significance'
              },
              {
                title: 'Thinkers',
                desc: 'We use critical and creative thinking skills to analyse and take responsible action on complex problems. We exercise initiative in making reasoned, ethical decisions'
              },
              {
                title: 'Communicator',
                desc: 'We express ourselves confidently and creatively in more than one language and in many ways. We collaborate effectively, listening carefully to the perspectives of other individuals and groups.'
              },
              {
                title: 'Principled',
                desc: 'We act with integrity and honesty, with a strong sense of fairness and justice, and with respect for the dignity and rights of people everywhere. We take responsibility for our actions and their consequences.'
              },
              {
                title: 'Open-minded',
                desc: 'We critically appreciate our own cultures and personal histories, as well as the values and traditions of others. We seek and evaluate a range of points of view, and we are willing to grow from the experience'
              },
              {
                title: 'Caring',
                desc: 'We show empathy, compassion and respect. We have a commitment to service, and we act to make a positive difference in the lives of others and in the world around us'
              },
              {
                title: 'Risk-takers',
                desc: 'We approach uncertainty with forethought and determination; we work independently and cooperatively to explore new ideas and innovative strategies. We are resourceful and resilient in the face of challenges and change'
              },
              {
                title: 'Balanced',
                desc: 'We understand the importance of balancing different aspects of our lives — intellectual, physical, (spiritual) and emotional — to achieve well-being for ourselves and others. We recognise our interdependence with other people and with the world in which we live'
              },
              {
                title: 'Reflective',
                desc: 'We thoughtfully consider the world and our own ideas and experience. We work to understand our strengths and weaknesses in order to support our learning and personal development.'
              }
            ]
          },

          High: {
            img: require('@/assets/images/reportCard/profile_MYP.jpg'),

            guide: [
              {
                title: 'Inquiries',
                desc: 'We nurture our curiosity, developing skills for inquiry and research. We know how to learn independently and with others. We learn with enthusiasm and sustain our love of learning throughout life'
              },
              {
                title: 'Knowledgeable',
                desc: 'We develop and use conceptual understanding, exploring knowledge across a range of disciplines. We engage with issues and ideas that have local and global significance'
              },
              {
                title: 'Thinkers',
                desc: 'We use critical and creative thinking skills to analyse and take responsible action on complex problems. We exercise initiative in making reasoned, ethical decisions'
              },
              {
                title: 'Communicator',
                desc: 'We express ourselves confidently and creatively in more than one language and in many ways. We collaborate effectively, listening carefully to the perspectives of other individuals and groups.'
              },
              {
                title: 'Principled',
                desc: 'We act with integrity and honesty, with a strong sense of fairness and justice, and with respect for the dignity and rights of people everywhere. We take responsibility for our actions and their consequences.'
              },
              {
                title: 'Open-minded',
                desc: 'We critically appreciate our own cultures and personal histories, as well as the values and traditions of others. We seek and evaluate a range of points of view, and we are willing to grow from the experience'
              },
              {
                title: 'Caring',
                desc: 'We show empathy, compassion and respect. We have a commitment to service, and we act to make a positive difference in the lives of others and in the world around us'
              },
              {
                title: 'Risk-takers',
                desc: 'We approach uncertainty with forethought and determination; we work independently and cooperatively to explore new ideas and innovative strategies. We are resourceful and resilient in the face of challenges and change'
              },
              {
                title: 'Balanced',
                desc: 'We understand the importance of balancing different aspects of our lives — intellectual, physical, (spiritual) and emotional — to achieve well-being for ourselves and others. We recognise our interdependence with other people and with the world in which we live'
              },
              {
                title: 'Reflective',
                desc: 'We thoughtfully consider the world and our own ideas and experience. We work to understand our strengths and weaknesses in order to support our learning and personal development.'
              }
            ]
          }
        },
        descriptors: {
          bg: require('@/assets/images/reportCard/descriptors.png')
        },
        achievements: {
          bg: require('@/assets/images/reportCard/achievements.png')
        },
        approachToLearning: {
          bg: require('@/assets/images/reportCard/approachToLearning.png')
        },
        coordinatorComment: {
          bg: require('@/assets/images/reportCard/coordinator_comment.png')
        }
      },
      orientation: 'landscape',
      fileName: '',
      pdfFile: '',
      showTheIntro: true,
      showIBProfile: false,
      showApproachToLearning: true,
      showAdvisorsComment: true,
      ////////////////////////////
      // Pages
      //////////////////////////
      pages: [
        {
          title: '',
          name: '',
          componentName: '',
          bodyBackgroundImg: ''
        }
      ]
    }
  },
  computed: {
    studentName() {
      return this.studentData?.student_full_name
    },
    studentId() {
      return this.$route.params.studentId
    },
    gradeName() {
      return this.studentData?.grade?.name || ''
    },
    className() {
      return this.studentData?.class?.name || ''
    },
    classId() {
      return this.studentData?.class?.id || null
    },
    subjectId() {
      return this.subjectsData[0]?.subject_id || null
    },

    pdfConfig() {
      return {
        orientation: this.orientation,
        unit: 'pt',
        fileName: this.fileName || `${this.studentName}`,
        format: {},
        // headStyles: {fontStyle: 'Amiri'},
        headStyles: {font: 'Amiri'}, // For Arabic text in the table head
        bodyStyles: {font: 'Amiri'} // For Arabic text in the table body
      }
    },
    coverStatic() {
      return this.staticContent.cover
    },
    schoolPrincipleIntroStatic() {
      return this.staticContent.schoolPrincipleIntro
    },
    learnerProfileStatic() {
      return this.staticContent.learnerProfile
    },
    descriptorsStatic() {
      return this.staticContent.descriptors
    },
    achievementsStatic() {
      return this.staticContent.achievements
    },
    coordinatorCommentStatic() {
      return this.staticContent.coordinatorComment
    },
    approachToLearningStatic() {
      return this.staticContent.approachToLearning
    },
    coreSubjectRepeatTimes() {
      return (
        Math.round(
          this.achievementCoreSubjects.length / this.pageAchivementsCapacity
        ) || 1
      )
    },
    MOESubjectsRepeatTimes() {
      return (
        Math.round(
          this.achievementMOESubjects.length / this.pageAchivementsCapacity
        ) || 1
      )
    },
    includeATL() {
      return Boolean(this.activeRound.include_ATL_in_the_report_card)
    },
    roundName() {
      return this.activeRound.title
    },
    showFinalGPA() {
      return Boolean(
        !this.isStrandApproach &&
          this.selectedStudent?.allocation_display_the_general_average_grade
      )
    },
    sectionCoordinator() {
      return this.studentData.section_coordinator_comment || null
    },
    showBoundariesAndDescriptors() {
      return Boolean(
        this.activeRound
          .display_the_boundaries_and_descriptors_in_the_report_card
      )
    },
    hasBoundaryLocalGrade() {
      return this.descriptors[0]?.local_grade !== null
    },
    hasBoundaryDescriptor() {
      return this.descriptors[0]?.dscriptor !== null
    },
    isAmerican() {
      return this.studentData?.division?.id === 2
    },
    isIB() {
      return this.studentData?.division?.id === 1
    },
    showPOIUnits() {
      return Boolean(this.activeRound.include_POI_in_the_report_card)
    },
    allowCoordinatorComment() {
      return (
        this.activeRound
          ?.allow_section_coordinator_to_update_a_General_comment || false
      )
    }
  },
  watch: {
    reportCardPrimaryColor(v) {
      function hexToRgb(hex) {
        const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16))
        return `${r},${g},${b}`
      }
      document.documentElement.style.setProperty('--reportCardPrimaryColor', v)
      document.documentElement.style.setProperty(
        '--reportCardPrimaryColor-rgba',
        hexToRgb(v)
      )
    }
  },
  methods: {
    //////////////////////
    // Loading
    //////////////////////
    // dialogs
    async startLoadingDescriptors() {
      await (this.loadingDescriptors = true)
      this.VsLoadingContained(this.$refs.loadingDescriptors)
    },
    async endLoadingDescriptors() {
      await this.stopVsLoadingContained(this.$refs.loadingDescriptors)
      this.loadingDescriptors = false
    },
    //////////////////////
    // APIs
    //////////////////////
    getAllFinalOutcomes() {
      this.startLoadingDialog()
      ReportCardRepo.getStudentAllFinalOutcome(
        this.selectedStudentId,
        this.activeRoundGradingId
      )
        .then((res) => {
          this.studentData = res.data.student_data
          this.selectedStudent = this.studentData
          this.reportCardData = res.data
          this.subjectsData = res.data.subjects_data
          this.selectedClass = {
            ...this.selectedStudent.class,
            section_id: this.selectedStudent.section.id
          }
          this.activeRound = res.data.student_data.round_data
          this.handleAchivementsData(res.data.subjects_data)
          this.getAllBoundaryByStudent()
          this.getATLData()
          if (this.isIB) {
            this.showIBProfile = true
          }
          if (this.isAmerican) {
            this.showApproachToLearning = false
            this.showAdvisorsComment = false
          }
          if (
            !this.activeRound
              .allow_section_coordinator_to_update_a_General_comment
          ) {
            this.showAdvisorsComment = false
          }
        })
        .catch(this.errMsg)
        .finally(this.endLoadingDialog)
    },
    async checkRoundOfClass() {
      // ---- Abort
      if (!this.classId || !this.subjectId) return
      this.loadingCard = true
      this.isRoundActive = false
      return await GradingRoundRepo.checkRoundOfClass(
        this.classId,
        this.subjectId
      )
        .then((res) => {
          // this.isRoundActive = Boolean(res.status !== 'error' && res.data !== 'not exist')
          this.activeRound = this.isRoundActive ? res.data : {}
          this.getATLData()
        })
        .catch(this.errMsg)
        .finally(() => {
          this.loadingCard = false
        })
    },
    getATLOptions() {
      ReportCardRepo.getATLOptions()
        .then((res) => {
          this.ATLOptions = res.data
        })
        .catch(this.errMsg)
    },
    getATLCategories() {
      ReportCardRepo.getATLCategories()
        .then((res) => {
          this.ATLCategories = res.data
        })
        .catch(this.errMsg)
    },
    async getCriterias() {
      // if (!this.classId || !this.subjectId) {
      //   this.errMsg(this.$t('form.the_grade_id_is_missed'))
      //   return
      // }
      // --- Continue
      const payload = {
        gradeId: this.selectedStudent.grade.id,
        subjectId: this.subjectId,
        classIds: [this.classId]
      }
      ExamRepo.getCriterias(payload)
        .then((res) => {
          this.criterias = this.handleCriteria(res.data)
        })
        .catch(this.errMsg)
    },
    getAllBoundaryByStudent() {
      this.startLoadingDescriptors()
      BoundaryRepo.getAllBoundaryByStudent(this.studentId)
        .then((res) => {
          if (res[0]) this.descriptors = res[0].limits
        })
        .catch((err) => this.errMsg(err, this.$t('grading.boundaries')))
        .finally(() => {
          this.endLoadingDescriptors()
        })
    },
    async uploadReportCarde() {
      if (!this.pdfFile) return

      this.startLoadingSubmit()
      const formData = new FormData()
      formData.append('user_id', this.studentId)
      formData.append('file', this.pdfFile)
      return await ReportCardRepo.uploadReportCarde(
        this.activeRoundGradingId,
        formData
      )
        .then(() => {
          this.successMsg(this.$t('form.saved_successfully'))
          this.pdfFile = ''
        })
        .catch(this.errMsg)
        .finally(() => {
          this.endLoadingSubmit()
        })
    },

    ///////////////////////
    // handle data
    ////////////////////////
    handleAchivementsData(data) {
      this.achievements = data
      this.achievementCoreSubjects = data.filter(
        (s) =>
          s.allocation?.Display_to_report_cared &&
          s.allocation?.add_to_grading_allocation
      )
      this.achievementMOESubjects = data.filter(
        (s) =>
          s.allocation?.Display_to_report_cared &&
          !s.allocation?.add_to_grading_allocation
      )
      if (this.isMYPSection) this.getCriterias()
      if (this.showFinalGPA) this.handleFinalGPA()
      if (this.studentData.strands?.length) {
        this.isStrandApproach = true
        this.achievements.forEach((s) => {
          s.strands = this.studentData.strands.filter(
            (e) => e.strand.subject.id === s.subject_id
          )
        })
      }
    },
    handleFinalGPA() {
      const achievements = this.achievementCoreSubjects
      if (this.isAmerican) {
        // gp => subject boundary grade_weight * subject credit hour weight
        // final => sum of gp / sum of the subjects credit hour weight
        let hasGP = false
        achievements.forEach((e) => {
          const hasWeights = e.boundary_limits && e.allocation.weight
          e.gp = hasWeights
            ? Number(e.allocation.weight) *
              Number(e.boundary_limits.grade_weight)
            : 0
          if (e.gp) hasGP = true
        })
        if (hasGP) {
          const GPSum = achievements
              .map((e) => Number(e.gp))
              .reduce((a, b) => a + b, 0),
            CreditHoursSum = achievements
              .map((s) => Number(s.allocation?.weight) || 0)
              .reduce((a, b) => a + b, 0)

          this.finalGPA = Number(parseFloat(GPSum / CreditHoursSum).toFixed(2))
        } else this.finalGPA = 0
      } else {
        const totalScorePercentage = achievements.map(
            (a) => a.total_score_percentage
          ),
          totalScorePercentageSum = totalScorePercentage.reduce(
            (a, b) => Number(a) + Number(b),
            0
          ),
          subjectLength = achievements.length
        const DPTotal = Number((totalScorePercentageSum / 100) * 7).toFixed(1)
        this.finalGPA = this.isDPSection
          ? DPTotal
          : totalScorePercentageSum / subjectLength
      }
    },
    getATLData() {
      if (this.includeATL) {
        this.getATLOptions()
        this.getATLCategories()
      }
    },
    ////////////////////////
    // Reset Data
    ////////////////////////
    resetData() {
      this.selectedStudent = {}
    },
    resetReportCardPagesLength() {
      const elements = document.querySelectorAll('.reportCardPage')
      this.reportCardPagesLength = elements.length
      elements.forEach((e, i) => {
        const currentPageEl = e.querySelectorAll('.currentPage')
        if (currentPageEl.length)
          currentPageEl.forEach((c) => {
            c.innerText = i + 1
          })
      })
    },
    ////////////////////////
    // print
    ////////////////////////
    async printPDF() {
      this.startLoadingSubmit()

      const pagesDiM = [],
        pagesImgs = [],
        pagesObj = {},
        pageCount = this.reportCardPagesNodes.length,
        getImages = () => {
          return new Promise((resolve) => {
            this.reportCardPagesNodes.forEach(async (p, i) => {
              const dim = {
                width: p.clientWidth * 0.5,
                height: p.clientHeight * 0.5
              }
              await this.html2PdfBase64NoPrint(p)
                .then((res) => {
                  pagesObj[`img_${i}`] = res
                  pagesObj[`dim_${i}`] = dim
                })
                .catch((err) => {
                  this.errMsg(err)
                  this.endLoadingSubmit()
                })

              const keysLength = Object.keys(pagesObj).length,
                isGetAllPages = keysLength / pageCount === 2
              if (isGetAllPages) resolve(true)
            })
          })
        }
      getImages()
        .then((res) => {
          if (res) {
            const stored = Object.keys(pagesObj)
              .sort()
              .reduce((obj, key) => {
                obj[key] = pagesObj[key]
                return obj
              }, {})

            for (const key in stored) {
              if (key.includes('img')) pagesImgs.push(stored[key])
              if (key.includes('dim')) pagesDiM.push(stored[key])
            }

            return this.imgs2Pdf(pagesImgs, pagesDiM, this.pdfConfig, false)
          }
        })
        .then((file) => {
          this.pdfFile = file
          this.uploadReportCarde()
        })
        .finally(() => {
          this.endLoadingSubmit()
        })
    },
    saveData() {
      this.printPDF()
    },
    //////////////////////
    // Helpers
    //////////////////////
    sliceArr(arr, currentRepeatTime) {
      const allRepeateTimes = Math.round(
          arr.length / this.pageAchivementsCapacity
        ),
        endIndex = this.pageAchivementsCapacity * currentRepeatTime, //
        startIndex = endIndex - this.pageAchivementsCapacity,
        restElms = arr.length - endIndex,
        isFirstPage = currentRepeatTime === 1,
        needMorePages =
          allRepeateTimes > currentRepeatTime
            ? true
            : restElms >= this.pageAchivementsCapacity,
        lastElement = needMorePages ? endIndex : arr.length,
        startElment = currentRepeatTime > 1 ? startIndex + 1 : startIndex

      return arr.slice(startIndex, lastElement)
    },
    getNameOfATLOption(subject, targetCategoryId) {
      const optionId = subject.reportCaredAtls.find(
          (e) => e.atl_category_id === targetCategoryId
        )?.atl_option_id,
        optionName = this.ATLOptions.find((e) => e.id === optionId)?.name || '-'

      return optionId ? optionName : '-'
    }
    //////////////////////
    // Go to
    ///////////////////////
  },
  created() {
    this.$store.commit('TOGGLE_REDUCE_BUTTON', true)
    this.getAllFinalOutcomes()
    this.reportCardPrimaryColor = this.themePrimaryColor
  },
  updated() {
    this.resetReportCardPagesLength()
  },
  mounted() {
    this.resetReportCardPagesLength()

    // Set intro text
    this.staticContent.schoolPrincipleIntro.introText = `<h4>
        Dear Parents,
        </br> </br>
        An education at ${this.institutionLongName} is about actively combining challenging and enriching experiences with academic rigor and creative opportunities.
        </br></br>
        We want our students to have the courage to push back the boundaries of their experience and to explore the possibilities that are
        available to them.
        <br><br>
        Whilst we are proud of their academic results, there is so much more to the education we provide. It is, therefore, important that parents
        wholeheartedly support the ethos of the school.
        <br><br>

        On this note, I would like to present this report card of this academic year. Let’s work together to create an environment for true
        development.
        <br><br>
        We look forward to the coming academic year. <br>
        Kindest Regards <br>
        ${this.institutionShortName} Management Team
        </h4>`
  },
  beforeDestroy() {
    this.$store.commit('TOGGLE_REDUCE_BUTTON', false)
    this.resetData()
  }
}
</script>
<style lang="scss">
@import 'components/pageLayout/style';
</style>
