<template>
  <div>
    <!-- Loader -->
    <div
      id="loadDialog"
      v-show="loadDialog"
      ref="loadDialog"
      class="vs-con-loading__container"></div>

    <!-- table -->
    <div class="table mb-20 w-full table--poi">
      <div class="table_title flex py-3">
        <h6 :class="titleClass"></h6>
        <h6
          class="flex-1 text-primary-light bold text-center"
          v-for="(v, i) in POIUnits"
          :key="'POI_' + i">
          {{ v.name }}
        </h6>
      </div>
      <ul class="table_list">
        <!--  description -->
        <li class="flex ">
          <p class="bold"
          :class="titleClass">Description</p>
          <p
            class="flex-1 ml-0 mr-auto"
            v-for="(u, i) in POIUnits"
            :key="'ach_' + i">
            {{ u.description }}
          </p>
        </li>
        <!--    central_idea    -->
        <li class="flex ">
          <p class="bold"
          :class="titleClass">{{ $t('poi.central_idea') }}</p>
          <p
            class="flex-1 ml-0 mr-auto"
            v-for="(u, i) in POIUnits"
            :key="'ach_' + i">
            {{ u.items.central_idea }}
          </p>
        </li>
        <!--    key_concepts    -->
        <li class="flex ">
          <p class="bold"
          :class="titleClass">{{ $t('poi.key_concepts') }}</p>
          <p
            class="flex-1 ml-0 mr-auto"
            v-for="(u, i) in POIUnits"
            :key="'ach_' + i">
            {{ u.items.key_concepts.map((e) => e.name).join(' , ') }}
          </p>
        </li>
        <!--    atl_skills    -->
        <li class="flex ">
          <p class="bold"
          :class="titleClass">{{ $t('poi.ATL_skills') }}</p>
          <p
            class="flex-1 ml-0 mr-auto"
            v-for="(u, i) in POIUnits"
            :key="'ach_' + i">
            {{ u.items.atl_skills.map((e) => e.name).join(' , ') }}
          </p>
        </li>
        <!--     Learner Profile     -->
        <li class="flex ">
          <p class="bold"
          :class="titleClass">{{ $t('poi.learner_profile') }}</p>
          <p
            class="flex-1 ml-0 mr-auto"
            v-for="(u, i) in POIUnits"
            :key="'ach_' + i">
            {{ u.items.learner_profile.map((e) => e.name).join(' , ') }}
          </p>
        </li>
        <!--      Lines Of Inquiry      -->
        <li class="flex ">
          <p class="bold"
          :class="titleClass">{{ $t('poi.lines_Of_inquiry') }}</p>
          <p
            class="flex-1 ml-0 mr-auto"
            v-for="(u, i) in POIUnits"
            :key="'ach_' + i"
            >
              <ol>
                  <li class="text-capitalize" v-for="(line,l_i) in u.items.lines_of_inquiry.split('-')" :key="l_i" v-if="line">- {{line}}</li>
              </ol>
         </p>
        </li>
        <!--        &lt;!&ndash;       Subject focus       &ndash;&gt;-->
        <!--        <li class="flex ">-->
        <!--          <p class="bold"
        :class=titleClass">{{ $t('poi.lines_Of_inquiry') }}</p>-->
        <!--          <p-->
        <!--            class="flex-1 ml-0 mr-auto"-->
        <!--            v-for="(u, i) in POIUnits"-->
        <!--            :key="'ach_' + i"-->
        <!--            v-html="u.items.extra_sections?."></p>-->
        <!--        </li>-->
      </ul>
    </div>

  </div>
</template>

<script>
// API
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const ReportCardRepo = RepositoryFactory.get('GradingModule', 'reportCard')

import gradingMixins from '@/modules/grading/grading.mixins'

export default {
  name: 'unit',
  data: () => ({
    POIUnits: []
  }),
  mixins: [gradingMixins],
  computed:{
    titleClass () {
      return this.POIUnits.length>4 ? 'flex-1' : 'w-1/6' 
    }
  },
  methods: {
    /////////////////////////
    // API
    ////////////////////////
    getPOIUnitsOfRoundGrade () {
      this.startLoadingDialog()
      ReportCardRepo.getPOIUnitsOfRoundGrade(
        this.selectedStudent.grade.id,
        this.activeRoundGradingId
      )
        .then((res) => {
          this.POIUnits = res.data
        })
        .catch(this.errMsg)
        .finally(this.endLoadingDialog)
    }
  },
  created () {
    this.getPOIUnitsOfRoundGrade()
  }
}
</script>

<style scoped></style>
